import React from "react";
import {ContactUsStyled} from "../components/Layout/styled";

const ContactUs = () => {
    return(
        <ContactUsStyled>
            <section id="description">
                <h1> Aquí un texto
                    para contactar </h1>
                <h2>
                    Se parte dde las comunidades pioneras en formar el nuevo...
                </h2>
                <h3> Encuéntranos también en: </h3>
            </section>

            <section id="formOne">
                <form>
                    <label> Correo electrónico <span> * </span> </label>
                    <input id="email" placeholder="Correo electrónico" />

                    <label> Nombre <span> * </span> </label>
                    <input id="name" placeholder="Nombre" />

                    <label> Comunidad/Organización <span> * </span> </label>
                    <input id="community" placeholder="Comunidad/Organización" />


                    <label> País  <span> * </span> </label>
                    <input id="community" placeholder="Comunidad/Organización" />


                    <label> Tamaño de la comunidad  <span> * </span> </label>
                    <input id="communitySize" placeholder="Tamaño de la comunidad" />

                    <label> ¿Por qué te interesa ingresar a la beta? </label>
                    <textarea id="beta" placeholder="Texto de intereses"  />
                    <p> Al registrarte, aceptas los términos y condiciones </p>
                    <button type="button" id="btnSubmit"> Enviar </button>
                </form>
            </section>

            {/* Iconos generales de burbuja */}
            <svg
                id="figureOne"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 308 496"
            >
                <defs>
                    <linearGradient id="linear-gradient" x1="1" y1="0.015" x2="0" y2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#f8a4d8"/>
                        <stop offset="1" stopColor="#6e64fe"/>
                        <stop offset="1" stopColor="#6c63ff"/>
                    </linearGradient>
                    <clipPath id="clip-path">
                        <ellipse id="Elipse_102" data-name="Elipse 102" cx="34" cy="33.5" rx="34" ry="33.5" transform="translate(-0.378)" fill="#fff" stroke="#707070" strokeWidth="1"/>
                    </clipPath>
                    <clipPath id="clip-path-2">
                        <circle id="Elipse_105" data-name="Elipse 105" cx="16.5" cy="16.5" r="16.5" transform="translate(0.325 0.128)" fill="#fff" stroke="#707070" strokeWidth="1"/>
                    </clipPath>
                </defs>
                <g id="Enmascarar_grupo_15" data-name="Enmascarar grupo 15" transform="translate(240.378 273)" clipPath="url(#clip-path)">
                    <rect id="Rectángulo_674" data-name="Rectángulo 674" width="100.9" height="88.975" rx="25" transform="translate(-16.654 -11.044)" fill="url(#linear-gradient)"/>
                </g>
                <g id="Enmascarar_grupo_18" data-name="Enmascarar grupo 18" transform="translate(40.675 -0.128)" clipPath="url(#clip-path-2)">
                    <rect id="Rectángulo_677" data-name="Rectángulo 677" width="50.077" height="44.159" rx="22.079" transform="translate(-8.265 -5.481)" fill="url(#linear-gradient)"/>
                </g>
                <path id="Sustracción_2" data-name="Sustracción 2" d="M-4689.853,666H-4830V289.147a287.837,287.837,0,0,1,38.465,21.867,289.23,289.23,0,0,1,42.476,35.045,289.179,289.179,0,0,1,35.045,42.475,286.986,286.986,0,0,1,26.46,48.752,285.569,285.569,0,0,1,16.723,53.873A289.078,289.078,0,0,1-4665,549a288.849,288.849,0,0,1-6.438,60.726A285.523,285.523,0,0,1-4689.853,666Z" transform="translate(4830 -170)" fill="url(#linear-gradient)"/>
            </svg>



        </ContactUsStyled>
    )
}
export default ContactUs;